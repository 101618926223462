<template>
  <div class="homslider-cls">
    <div class="hm-slider-responsive" v-if="is_scrren_hide" >
      <home-nav-bar v-on:isDelivery="isHomeDeliverySet" v-on:getLatLong="onMobileLocationClick" :singleRestaurant="singleRestaurant"></home-nav-bar>
    </div>
    <div class="home-slider-main myhome-slider">      
      <div id="testimonial-slider" class="owlcarousel">
        <VueSlickCarousel v-if="banners && banners.length > 0 && $auth.setting" v-bind="settings" class="pill-nav round-arrow" >
          <div v-for="(banner,index) in banners" :key="index" class="vue-slide-carousel">
            <router-link  :to="{name:'banner-items', params:{banner_id: banner.id}}" v-if="banner.type =='restaurant' && banner.banner_restaurant.length > 1 && $auth.setting && (($auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item > 1) || (($auth.setting.product_type.id == 1 && $auth.setting.business_category_item <= 1) || ($auth.setting.product_type.id == 2 && $auth.setting.store_selection == 1)))">
              <div class="testimonial-item equal-height style-6 1" :class="$auth.setting.app_layout && $auth.setting.app_layout.length > 0 && $auth.setting.app_layout[0].banner_app_layout == '4'?'newBannerLayout':''">
                <img :src="banner.image" />
              </div>
            </router-link>
            <router-link v-if="banner.type =='restaurant' && banner.banner_restaurant.length == 1 && $auth.setting && (($auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item > 1) || (($auth.setting && $auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item <= 1) || ($auth.setting.product_type && $auth.setting.product_type.id == 2 && $auth.setting.store_selection == 1)))" :to="{name:'vendor', params:{restaurant_name: banner.banner_restaurant[0].slug}}">
              <div class="testimonial-item equal-height style-6 2" :class="$auth.setting && $auth.setting.app_layout && $auth.setting.app_layout.length > 0 && $auth.setting.app_layout[0].banner_app_layout == '4'?'newBannerLayout':''">
                <img :src="banner.image" />
              </div>
            </router-link>
            <a :href="(banner.link.includes('http')?banner.link:'//'+banner.link)" target="_blank" v-if="banner.type =='link' && banner.banner_restaurant.length == 0 && banner.menu_category_id == '0'" >
              <div class="testimonial-item equal-height style-6 3" :class="$auth.setting && $auth.setting.app_layout && $auth.setting.app_layout.length > 0 && $auth.setting.app_layout[0].banner_app_layout == '4'?'newBannerLayout':''">
                <img :src="banner.image" />
              </div>
            </a>
            <div v-if="banner.type =='noaction' && banner.banner_restaurant.length == 0" class="testimonial-item equal-height style-6 4" :class="$auth.setting && $auth.setting.app_layout && $auth.setting.app_layout.length > 0 && $auth.setting.app_layout[0].banner_app_layout == '4'?'newBannerLayout':''">
                <img :src="banner.image" />
            </div>        
            <a href="javascript:void(0);" v-if="banner.type =='restaurant' && $auth.setting && $auth.setting.product_type && ($auth.setting.product_type.id == 2 && $auth.setting.store_selection == 0) && banner.menu_category_id == '0'" >
              <div class="testimonial-item equal-height style-6 5" :class="$auth.setting && $auth.setting.app_layout && $auth.setting.app_layout.length > 0 && $auth.setting.app_layout[0].banner_app_layout == '4'?'newBannerLayout':''">
                <img :src="banner.image" />
              </div>
            </a>
            <a href="javascript:void(0);" v-if="$auth.setting && $auth.setting.product_type && ($auth.setting.product_type.id == 2 && $auth.setting.store_selection == 0) && banner.menu_category_id != '0'" @click="getBannerCategoryId(banner.menu_category_id)" >
              <div class="testimonial-item equal-height style-6 6" :class="$auth.setting && $auth.setting.app_layout && $auth.setting.app_layout.length > 0 && $auth.setting.app_layout[0].banner_app_layout == '4'?'newBannerLayout':''">
                <img :src="banner.image" />
              </div>
            </a>
            <!---- Single vendor for start -------------->
          </div>
        </VueSlickCarousel>
        <a v-if="loading && banners && banners.length == 0" v-for="prod in 3" href="javascript:void(0)" :key="prod" class="pill-nav round-arrow">
          <div class="menucate-cls" style="width:unset">
            <skeleton-loader-vue type="string" height="150px" class="menu-cat-img" width="420px" />
          </div>
        </a>
      </div>   
    </div>
   
  </div>
</template>

<script>
import { mapActions,mapGetters,mapMutations } from 'vuex';
import HomeNavBar from "@/components/HomeNavBar.vue";
import VueSlickCarousel from 'vue-slick-carousel';
import VueSkeletonLoader from 'skeleton-loader-vue';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  props:["is_pickup_delivery","singleRestaurant"],
  components: {HomeNavBar,VueSlickCarousel, 'skeleton-loader-vue': VueSkeletonLoader,},
  data() {
    return {
      loading: true,
      banners:[],
      is_scrren_hide:(window.screen.width < 1024 ? true:false),      
      settings:{
          "autoplay": true,
          "focusOnSelect": true,
          // "touchThreshold": 5,
          "dots": false,
          "arrows": true,
          "infinite": true,
          "speed": 500,
          "rows": 1,
          "slidesToShow": 3.5,
          "slidesToScroll": 3.5,          
          "responsive": [
            {
              "breakpoint": 1024,
              "settings": {
                "slidesToShow": 3,
                "slidesToScroll": 3,
                // "infinite": true,
                // "dots": true
              }
            },
            {
              "breakpoint": 600,
              "settings": {
                "slidesToShow": 4,
                "slidesToScroll": 4,
                "initialSlide": 4
              }
            },
            {
              "breakpoint": 480,
              "settings": {
                "slidesToShow": 1,	
                "slidesToScroll": 1,	
                // "dots": true,	
                // "arrows":false
              }
            }
          ]
      },
    };
  },

  mounted(){
    this.getBanner();
  },

  watch:{    
    // latitude:function(){      
    //   setTimeout(() =>{
    //     this.$store.commit("restaurant/bannerListMutation",[]);
    //     this.getBanner();        
    //   },1000)
    // },

    is_pickup_delivery:function(){      
      setTimeout(() =>{        
        this.getBanner();
      },1000)
    }
  },

  computed: {    
    ...mapGetters("restaurant", ["bannerListState"]),   
  },

  methods: {
    ...mapActions("restaurant",["getBanners","getBannerCategoryData"]),
    ...mapMutations("restaurant",["bannerListMutation"]),
    
    
    isHomeDeliverySet(value){
      this.$emit("isDeliveryHome", value);
      this.$store.commit("order/pickupdelivery",value);
    },

    onMobileLocationClick(value){
      this.$emit("getMobileLatLong", value);
    },

    getBanner() {      
      if(this.bannerListState && this.bannerListState.length > 0){
        this.banners = this.bannerListState;
        this.getSlideSettings();
      }else{
        let isGetRestaurant = true;
        // if(latitude) {
          if(this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0) {
            return this.getBannerCategoryData({
              vendor_id: this.$auth.getVendorId(),
              delivery_type_time_slots : this.is_pickup_delivery.toString(),
              is_langauge: this.$store.state.lang.locale,
              cityname:localStorage.getItem("city"),
              state:localStorage.getItem("state"),
            }).then((data)=>{
              if(data.code == 200) {
                this.banners = data.Result;
                isGetRestaurant = true;
                this.loading = false;
                this.$emit("getRestaurantData",isGetRestaurant);                
                this.getSlideSettings();
              }else if(data.code == 101){
              isGetRestaurant = false;
              this.banners=[];
              this.loading = false;
              this.$emit("getRestaurantData",isGetRestaurant);
            }else {
              this.banners=[];
              this.loading = false;
            }
          })
          }else if(this.$auth.setting && (this.$auth.setting.product_type && this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item > 1) || ((this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item <= 1) || (this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 1))) {          
            return this.getBanners({
              vendor_id: this.$auth.getVendorId(),
              delivery_type_time_slots : this.is_pickup_delivery,
              cityname:localStorage.getItem("city"),
              state:localStorage.getItem("state"),
            }).then((data)=>{
              if(data.code == 200){
                this.banners = data.Result;
                isGetRestaurant = true;
                this.loading = false;
                this.$emit("getRestaurantData",isGetRestaurant);
                this.getSlideSettings();
              }else if(data.code == 101){
                isGetRestaurant = false;
                this.banners=[];
                this.loading = false;
                this.$emit("getRestaurantData",isGetRestaurant);
              }else {
                this.banners=[];
                this.loading = false;
              }
            })
          }
        // }   
      }   
    },
    
    getBannerCategoryId(id){
      this.$emit("setSingleRestaurantId", id);
    },

    getSlideSettings(){
      this.settings = {
        "autoplay": true,
        "focusOnSelect": true,
        // "touchThreshold": 5,
        "dots": false,
        "arrows": true,
        "infinite": true,
        "speed": 500,
        "rows": 1,
        "slidesToShow": 3,
        "slidesToScroll": 3,          
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              // "infinite": true,
              // "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 4,
              "initialSlide": 4
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,	
              "slidesToScroll": 1,	
              // "dots": true,	
              // "arrows":false
            }
          }
        ]
      }
    }

  }
};
</script>
<style>
.newBannerLayout {
  margin: 10px 20px 20px 0 !important;
}
.myhome-slider .testimonial-item.newBannerLayout img {  
  height: 250px;
}

</style>